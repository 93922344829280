import React from "react";
import Header from "../components/Top";
import Home2 from "../components/Home2";
import Contact from "../components/Contact";
import Doctors from "../components/Doctors";
import MedServ from "../components/MedServ";

function Home() {
  return (
    <div>
      <Header />
      <Home2 />
      <MedServ />
      <Doctors />
      <Contact />
    </div>
  );
}

export default Home;
