import React from "react";
import Header from "./Top";

function Home2() {
  return (
    <div
      id="home"
      style={{ display: "flex", flexDirection: "column" }}
      className="whatWeDo"
    >
      {/* <img className="homedp" src={require("../images/bgh.jpg")} alt="home" /> */}
      <div className="bgImages">
        <div className="homedptDiv">
          <img
            className="homedpt"
            src={require("../images/bikeHome1.png")}
            alt="home"
          />
        </div>
        <div className="homeText"
        >
          <h2 className="bgText">
            Stay up-to-date with your health status at your convenience while
            keeping safe
          </h2>
          <h2 className="bgText2">Early detection is the key</h2>
          <h2 className="bgText2">Run a test today</h2>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "100px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3 className="topic" style={{ paddingLeft: "10px", fontSize: "40px" }}>
          What we do
        </h3>
        <hr style={{ width: "150px", marginTop: "20px" }} />
      </div>
      <div
        className="topFull"
        style={{
          marginTop: "10px",
          // position: "absolute",
          // top: "730px",
        }}
      >
        <div className="topInner" style={{ display: "flex", flex: "1" }}>
          <img
            className="icon"
            src={require("../images/micrs.jpg")}
            alt="icon"
          />
          <div className="topLast">
            <p className="text">
              Diagnosis, Research, Consultancy, Home Medical and Laboratory
              Services
            </p>
          </div>
        </div>
        <div className="topInner" style={{ display: "flex", flex: "1" }}>
          <img
            className="icon"
            src={require("../images/doctor.png")}
            alt="icon"
          />
          <div className="topLast">
            <p className="text">
              Diagnosis, Research, Consultancy and Home Telemedical Services
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home2;
