import "./App.css";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
// import MedServ from "./components/MedServ";
import Signup from "./pages/Signup";

function App() {
    return (
      <Router>
        <Routes>
          <Route index element={<Home/>} />
          <Route path="/home" element={<Home />} />
          {/* <Route path="/Medserv" element={<MedServ />} /> */}
          <Route path="/Login" element={<Login/>} />
          <Route path="/Signup" element={<Signup/>} />
        </Routes>
      </Router>
    );
  }

export default App;
