import React from "react";
import HeaderLower from "../components/HeaderLower";

function Signup() {
  return (
    <div>
      <HeaderLower />
      <div className="logContainer">
        <div className="logMain">
          <div className="logLeft">
            <p className="logTitleL">Sign up to create an account</p>
            <form className="formReg">
              <div>
                <input
                  className="formInp"
                  type="text"
                  name="firstname"
                  placeholder="first name"
                />
                <input
                  className="formInp"
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                />
                <input
                  className="formInp"
                  type="text"
                  name="phone"
                  placeholder="phone"
                />
                <input
                  className="formInp"
                  type="text"
                  name="email"
                  autoComplete="true"
                  placeholder="email"
                />
                <input
                  className="formInp"
                  type="text"
                  name="email"
                  placeholder="email"
                />
                <input
                  className="formInp"
                  type="password"
                  name="password"
                  placeholder="password"
                />
              </div>
              <div>
                <input className="formSubmit" type="submit" value="Submit" />
              </div>
            </form>
          </div>
          <div className="logRight" style={{ margin: "10px" }}>
            <p className="logTitleR">Login</p>
            <p className="logTitleP">Already have an account? Please login</p>
            <input className="formSubmit" type="submit" value="Sign in" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
