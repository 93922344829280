import React from "react";
import HeaderLower from "../components/HeaderLower";

function Login() {
  return (
    <div>
      <HeaderLower />
      <div className="logContainer">
        <div className="logMain">
          <div className="logLeft">
            <p className="logTitleL">Login to your account</p>
            <form className="formLog">
              <>
                <input
                  className="formInpL"
                  type="text"
                  name="email"
                  placeholder="email"
                />
                <input
                  className="formInpL"
                  type="password"
                  name="password"
                  placeholder="password"
                />
              </>
              <>
                <input className="formSubmit" type="submit" value="Submit" />
              </>
            </form>
          </div>
          <div className="logRight">
            <p className="logTitleR">New here?</p>
            <p className="logTitleP">Sign up to create your account now</p>
            <input className="formSubmit" type="submit" value="Sign up" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
