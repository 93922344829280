import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

function HeaderLower() {
  const more = useRef(0);
  const [val, setVal] = useState(1);
  const moreOptions = () => {
    setVal((v) => v + 1);
    more.current = val + 1;
    // alert(val);
  };
  const lessOption = () => {
    setVal((v) => 0);
    more.current = 0;
  };
  // useEffect(() => {
  //   setVal((v) => v + 1);
  // }, val);

  return (
    <div>
      <div className="containerH">
        <div className="moreOpt">
          <div onMouseLeave={lessOption}>
            <div className={more.current < 2 ? "nomoreOptSty" : "moreOptSty"}>
              <a href="#home">
                <Link to="/Home">Home</Link>
              </a>

              <a href="#login">
                <Link to="/Login">Login</Link>
              </a>
              <a href="#signup">
                <Link to="/Signup">Signup</Link>
              </a>
            </div>
          </div>
        </div>
        <div className="header">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "130px", height: "7vh" }}
              className="logo"
              src={require("../images/logoFull.png")}
              alt="logo"
            />
          </div>
          <div className="headerRest">
            <a href="#home">
              <Link to="/Home">Home</Link>
            </a>

            <a href="#login">
              <Link to="/Login">Login</Link>
            </a>
            <a href="#signup">
              <Link to="/Signup">Signup</Link>
            </a>
          </div>
        </div>
        <div onMouseEnter={moreOptions}>
          <img
            src={require("../images/more.png")}
            className={more.current < 2 ? "more" : "nomore"}
          />
        </div>
      </div>
    </div>
  );
}

export default HeaderLower;
