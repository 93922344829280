import React from "react";
import "../App.css";
export default function Contact() {
  return (
    <div>
      <div id="contact" className="contactContainer">
        {/* the contact id is positioned here */}
        <p className="contText">contact us today</p>
        <div className="contacts">
          <div className="contactFirst">
            <img
              className="contactLogo"
              src={require("../images/logo4.png")}

              alt="logo"
            />
            <h3 className="ces">
              CES SHUTTLE MEDICAL LABORATORY
            </h3>
          </div>
          <hr
            style={{
              height: "200px",
              width: "4px",
              backgroundColor: "white",
              outline: "none",
              color: "blue",
              marginLeft: "10px",
            }}
          />
          <div className="contactSecond">
            <h2
              style={{
                color: "white",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Located at
            </h2>
            <p
              style={{
                color: "#ccc",
                fontSize: "17px",
                display: "flex",
                flex: 1,
                textAlign: "center",
              }}
            >
              Along Goodluck Ebele Jonathan road, Jos, Plateau state
            </p>
          </div>
          <hr
            style={{
              height: "200px",
              width: "5px",
              backgroundColor: "white",
              outline: "none",
              color: "blue",
              marginLeft: "10px",
            }}
          />
          <div className="contIconsSet">
            <a
              className="anchor"
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61551375284251&mibextid=ZbWKwL"
            >
              <img
                style={{ width: "60px" }}
                className="contactIcon"
                src={require("../images/fb4.webp")}
                alt="facebook"
              />
            </a>
            <a
              className="anchor"
              target="_blank"
              href="https://twitter.com/shuttle47944"
            >
              <img
                className="contactIcon"
                src={require("../images/twit.png")}
                alt="twitter"
              />
            </a>

            <a className="anchor" href="whatsapp">
              <img
                className="contactIcon"
                src={require("../images/whats.png")}
                alt="whatsapp"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
