import React from "react";

function Doctors() {
  const services = [
    {
      name: "Ahoame Isaac Msughter",
      details: "Chief Executive Officer",
      time: "any time and day",
      doctors: "5 available doctors",
      id: 1,
      images: require("../images/Isa.jpg"),
    },
    {
      name: "Chineme Reuben Esor",
      details: "Chief Technical Officer",
      time: "any time and day",
      doctors: "5 available doctors",
      id: 2,
      images: require("../images/kleo - Copy.jpg"),
    },
    {
      name: "Kakji Kitwuna John",
      details: "Medical Laboratory Scientist",
      time: "any time and day",
      doctors: "5 available doctors",
      id: 3,
      images: require("../images/hod.jpg"),
    },
    {
      name: "Dr. Esla Jephthah Enjugu",
      details: "Medical Doctor",
      time: "any time and day",
      doctors: "5 available doctors",
      id: 4,
      images: require("../images/Esla.jpg"),
    },
    {
      name: "Philip Atsen",
      details: "Medical Laboratory Scientist",
      time: "any time and day",
      doctors: "5 available doctors",
      id: 4,
      images: require("../images/Phil - Copy.jpg"),
    },
  ];
  return (
    <div>
      <div className="docMain">
        <h3
          style={{
            textAlign: "center",
            fontSize: "44px",
            margin: "50px",
            padding: "10px",
            // fontFamily: "akaya"
          }}
        >
          Meet our Team
        </h3>
        {/* <p style={{ textAlign: "center", marginBottom: "70px" }}>
          We offer the following services at your convenience
        </p> */}
        <div className="docCover">
          {services.map((x) => {
            return (
              <div key={x.id} className="cust">
                <div>
                  {<img src={x.images} alt="service" className="docImage" />}
                </div>
                <div className="docContent">
                  <p className="custTitle">{x.name}</p>
                  <p className="docRole">{x.details}</p>
                  {/* <h4>{x.doctors}</h4> */}
                  {/* <h6>{x.time}</h6> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Doctors;
