import React from "react";
import "../index.css";

function MedServ() {
  const services = [
    {
      name: "Order for test",
      details: "Medical Laboratory at your doorstep",
      time: "Mon-Friday(7a.m -7pm)",
      doctors: "Saturday&Sunday( 10 am -4pm)",
      id: 1,
      images: require("../images/orderTest.jpg"),
    },
    {
      name: "Chat with doctor",
      details: "Link up with qualified and licensed Doctors",
      time: "Mon-Friday(7a.m -7pm)",
      doctors: "Saturday&Sunday( 10 am -4pm)",
      id: 2,
      images: require("../images/chatDoc.jpeg"),
    },
    {
      name: "Chat with specialist",
      details: "Connect with specialized Medical Health practitioners",
      time: "Mon-Friday(7a.m -7pm)",
      doctors: "Saturday&Sunday( 10 am -4pm)",
      id: 3,
      images: require("../images/chatSpec.jpg"),
    },
    {
      name: "Order for medications",
      details: "Receive medication at your doorstep",
      time: "Mon-Friday(7a.m -7pm)",
      doctors: "Saturday&Sunday( 10 am -4pm)",
      id: 4,
      images: require("../images/orderMed.jpg"),
    },
  ];
  return (
    <div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {/* the about us id is positioned here */}
        <hr
          id="about"
          style={{
            width: "300px",
            backgroundColor: "blue",
            height: "1px",
          }}
        />
      </div>
      <div className="misVisCont">
        <div>
          <div className="misVis" style={{ marginTop: "50px" }}>
            <div
              className="mission"
              style={{ boxShadow: "2px 3px 5px", padding: "20px" }}
            >
              <h2
                style={{
                  margin: "20px",
                  color: "#555",
                  fontSize: "30px",
                  textAlign: "center",
                }}
              >
                Our Mission
              </h2>
              <p
                style={{
                  fontSize: "18px",
                  textAlign: "justify",
                  lineHeight: "25px",
                }}
              >
                Our mission is to empower individuals by providing a convenient
                and reliable platform for medical testing and diagnostics.
                Through the CES Shuttle Medical Laboratory Mobile App, we are
                committed to delivering accurate, efficient, and secure testing
                services, enabling users to take control of their health
                journeys while contributing to the advancement of medical
                science and knowledge.
              </p>
            </div>
            <div
              className="vision"
              style={{ boxShadow: "2px 3px 5px", padding: "20px" }}
            >
              <h2
                style={{
                  margin: "20px",
                  color: "#555",
                  fontSize: "30px",
                  textAlign: "center",
                }}
              >
                Our Vision
              </h2>
              <p
                style={{
                  fontSize: "18px",
                  textAlign: "justify",
                  lineHeight: "25px",
                }}
              >
                To revolutionize healthcare accessibility and patient-centered
                diagnostics through cutting-edge technology. We envision the CES
                Shuttle Medical Laboratory App as a transformative tool that
                brings comprehensive medical care directly to patients'
                fingertips, ensuring timely and accurate diagnoses, fostering
                proactive healthcare management, and ultimately improving and
                saving lives.
              </p>
            </div>
          </div>
          {/* <div
            className="obj"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                marginTop: "20px",
                color: "#555",
                marginBottom: "10px",
                justifyContent: "center",
                display: "flex",
                flex: "1",
                boxShadow: "2px 3px 5px",
                padding: "200px 0px",
              }}
            >
              Our Objectives
            </h2>

            <ul
              style={{
                display: "flex",
                margin: "0 30px",
                fontSize: "18px",
                textAlign: "justify",
                flexDirection: "column",
                flex: "4",
              }}
            >
              <li>
                Seamless Accessibility: Develop an intuitive and user-friendly
                mobile app that allows users to easily schedule, request, and
                manage medical tests from the comfort of their homes,
                workplaces, or any location.
              </li>
              <br />
              <li>
                Comprehensive Diagnostics: Collaborate with leading medical
                professionals and laboratories to offer a wide range of medical
                tests, spanning from routine check-ups to specialized
                diagnostics, ensuring users have access to holistic health
                insights.
              </li>
              <br />
              <li>
                Accuracy and Reliability: Implement rigorous quality control
                measures to ensure the accuracy and reliability of test results,
                adhering to industry standards and guidelines.
              </li>
              <br />
              <li>
                Data Security and Privacy: Prioritize data security and user
                privacy by employing advanced encryption protocols and complying
                with regulations such as HIPAA to safeguard sensitive medical
                information.
              </li>
              <br />
              <li>
                Real-time Notifications: Provide users with timely notifications
                throughout the testing process, including appointment
                scheduling, test status updates, and result availability.
              </li>
              <br />
              <li>
                Educational Resources: Offer educational content within the app
                to help users understand their test results, promote health
                literacy, and encourage informed decision-making about their
                health.
              </li>
              <br />
              <li>
                Collaboration with Healthcare Providers: Establish partnerships
                with healthcare providers and practitioners to enable seamless
                integration of test results into users' medical records,
                fostering continuity of care.
              </li>
              <br />
              <li>
                Continuous Innovation: Stay at the forefront of technological
                advancements in medical diagnostics, incorporating features such
                as AI-powered predictive health insights and telemedicine
                consultations to enhance the user experience.
              </li>
              <br />
              <li>
                Community Engagement: Engage with users through social media,
                webinars, and health awareness campaigns to promote preventive
                healthcare practices and encourage a proactive approach to
                well-being.
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <div className="serviceMain" style={{ marginTop: "-5px" }}>
        <h3
          style={{ textAlign: "center", fontSize: "40px", marginTop: "100px" }}
        >
          Medical and Laboratory Services
        </h3>
        <p style={{ textAlign: "center", fontSize: "24px" }}>
          We offer the following services at your convenience
        </p>
        <div className="cover">
          {services.map((x) => {
            return (
              <div key={x.id} className="serve">
                <div className="serviceImageDiv">
                  {
                    <img
                      src={x.images}
                      alt="service"
                      className="serviceImage"
                    />
                  }
                </div>
                <div className="content">
                  <p style={{ fontSize: "30px",textAlign:"center" ,fontFamily:"akaya" }} className="title">
                    {x.name}
                  </p>
                  <p style={{ fontSize: "20px" }}>{x.details}</p>
                  <h4>{x.doctors}</h4>
                  <h4>{x.time}</h4>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="choose">
        <div className="chooseImgs">
          <img
            className="chooseImg"
            src={require("../images/micrs.jpg")}
            alt="pic"
          />
          <img
            className="chooseImg"
            src={require("../images/homes.jpg")}
            alt="pic"
          />
          <img
            className="chooseImg"
            src={require("../images/chatDoc.jpeg")}
            alt="pic"
          />
          <img
            className="chooseImgSm"
            src={require("../images/testo.jpg")}
            alt="pic"
          />
        </div>
        <div className="chooseText">
          <h3 style={{ fontSize: "44px" }}>Why choose us?</h3>
          <br />
          <p style={{ textAlign: "justify", marginTop: "10px" }}>
            <ol>
              <li>
                {" "}
                Connecting Patients and Doctors through Telemedicine and medical
                Laboratory Scientists
              </li>
              <li>Caring for parents and loved ones back home</li>
              <li>Comfort and Convenience</li>
              <li>Easy access to specialists</li>
              <li>Medical access for people without health insurance</li>
              <li>Reduced exposure to pathogens</li>
            </ol>
          </p>
          <p style={{ textAlign: "justify", marginTop: "30px" }}>
            <h3>Our values</h3>
            <ul>
              <li>Compassion</li>
              <li>Respect for persons</li>
              <li>Commitment to integrity and ethical practice</li>
              <li>Excellence and justice</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default MedServ;
